.pageInner {
  display: grid;
  grid-template-rows: auto 1fr;
}

.pageHeader {
  display: grid;
  padding: 48px 8.6% 60px 8.6%;
  position: relative;

  background-image: url('/src/_img/nmo_top.jpg');
  background-size: cover;
  background-position: cneter;
}

.pageHeader-top {
  height: 58px;
  margin-bottom: 44px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'back lang auth';
  gap: 24px;
  justify-content: center;
  align-items: center;
}

.pageHeader-logo {
  position: absolute;
  width: 36px;
  top: 30px;
  left: 34px;
}

.pageHeader-back {
  grid-area: back;
}

.pageHeader-lang {
  grid-area: lang;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.pageHeader-auth {
  grid-area: auth;
}

.pageHeader-backlink::before {
  width: 40px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
  content: '';
  -webkit-mask: url('../_img/LeftArrow.svg');
  mask: url('../_img/LeftArrow.svg');
  -webkit-mask-size: cover;
  mask-size: cover;
  background: currentColor;
}

.pageInner-middle {
  padding: 74px 8.3%;
  background-color: var(--bg-highlited);

  background-size: cover;
  background-position: top;
}

.pageInner-main {
  padding: 74px 8.3%;
  display: flex;
  flex-direction: column;
  gap: 74px;
}

@media (max-width: 1200px) {
  .pageHeader {
    padding: 26px 32px 60px 32px;
  }

  .pageHeader-top {
    margin-top: 16px;
    height: 48px;
    margin-left: 84px;
    margin-bottom: 32px;
  }

  .pageHeader-logo {
    font-size: 18px;
    width: 30px;
    top: 26px;
    left: 32px;
  }

  .pageInner-middle {
    padding: 32px;
  }

  .pageInner-main {
    padding: 32px;
    gap: 32px;
  }
}

@media (max-width: 550px) {
  .pageHeader {
    padding: 20px 40px;
  }
  .pageHeader-logo {
    display: none;
  }
  .pageHeader-top {
    margin-left: 0px;
    margin-bottom: 6px;
  }
  .pageHeader-backlink::before {
    width: 20px;
    height: 9px;
    margin-right: 8px;
  }
  .pageInner-middle {
    padding: 24px;
  }
  .pageInner-main {
    padding: 24px;
    gap: 24px;
  }
}

@media (max-width: 350px) {
  .pageHeader {
    padding: 8px 12px;
  }
  .pageHeader-backlink::before {
    width: 12px;
    height: 8px;
    margin-right: 4px;
  }
  .pageInner-middle {
    padding: 20px;
  }
  .pageInner-main {
    padding: 20px;
    gap: 20px;
  }
}

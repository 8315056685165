.eventShedule {
}
.eventShedule-date {
  margin: 20px 0;
  font-size: 24px;
  font-weight: 400;
}
.eventShedule-timeline {
  padding: 6px 0;
  border-left: 1px solid var(--timeline-color);
}

.eventShedule-unit {
  margin: 0 22px;
  padding-top: 0;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: 160px 1fr 1fr;
  column-gap: 60px;
  position: relative;
}

.eventShedule-time {
  grid-column: 1;
  font-size: 14px;
  font-weight: 400;
  margin: 6px 0;
  position: relative;
}

.eventShedule-time:before {
  content: '●';
  color: var(--timeline-color);
  position: absolute;
  left: -27px;
  top: -0.25em;
  z-index: 5;
}

.eventShedule-title {
  grid-column: 2;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.eventShedule-description {
  margin-top: 6px;
  font-size: 14px;
  color: var(--color-label);
}

.eventShedule-now {
  position: absolute;
  background-color: var(--color-now);
  width: 1px;
  left: -29px;
  height: 8px;
  margin-top: 4px;
  box-sizing: content-box;
  border: 6px solid var(--bg-main);
}
.eventShedule-now::before {
  content: 'Сейчас';
  position: absolute;
  color: var(--color-now);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-left: -5em;
  margin-top: -0.5em;
  top: 50%;
}

.eventSheduleSpeaker {
  margin-bottom: 1em;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 18px;
}

.eventSheduleSpeaker-photo {
  width: 60px;
  height: 60px;
  margin-left: 30px;
}
.eventSheduleSpeaker.primary {
  font-size: 15px;
}
.eventSheduleSpeaker.primary .eventSheduleSpeaker-photo {
  width: 120px;
  height: 120px;
  margin-left: 0;
}
.eventSheduleSpeaker-info {
  align-self: center;
}
.eventSheduleSpeaker-name {
  font-weight: 500;
}

.eventSheduleSpeaker-regalia {
  color: var(--color-regalia);
}

@media (max-width: 1200px) {
  .eventShedule {
    overflow-wrap: anywhere;
  }
  .eventShedule-unit {
    grid-template-columns: 6em 5fr 6fr;
    gap: 15px;
  }
  .eventSheduleSpeaker {
    column-gap: 12px;
  }
  .eventSheduleSpeaker-photo {
    width: 48px;
    height: 48px;
    margin-left: 6px;
  }
  .eventSheduleSpeaker.primary .eventSheduleSpeaker-photo {
    width: 64px;
    height: 64px;
    margin-left: 0;
  }
  .eventShedule-now::before {
    display: none;
  }
}

@media (max-width: 550px) {
  .eventShedule-date {
    margin: 18px 0;
    font-size: 22px;
  }
  .eventShedule-unit {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .eventSheduleSpeaker {
    column-gap: 12px;
  }
  .eventSheduleSpeaker-photo {
    width: 36px;
    height: 36px;
    margin-left: 6px;
  }
  .eventSheduleSpeaker.primary .eventSheduleSpeaker-photo {
    width: 48px;
    height: 48px;
    margin-left: 0;
  }
  .eventShedule-title {
    font-size: 18px;
  }
  .eventSheduleSpeaker.primary {
    font-size: 14px;
  }
  .eventSheduleSpeaker {
    font-size: 12px;
  }
}

@media (max-width: 350px) {
  .eventShedule-date {
    margin: 12px 0;
    font-size: 20px;
  }
  .eventShedule-title {
    font-size: 16px;
  }
  .eventShedule-unit {
    margin: 0 14px;
  }
  .eventShedule-time:before {
    left: -19px;
  }
}

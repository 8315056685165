.catalogAside {
  position: relative;
  display: grid;
  grid-template-rows: 150px auto 1fr;
  row-gap: 20px;
  padding: 20px 50px;
  height: 100vh;
  min-height: 380px;
}

.catalogAside-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

.catalogAside-logo {
  margin-bottom: 24px;
}

.catalogAside-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.catalogAside-lang {
  margin-top: 1em;
}

.catalogAside-specialities {
  overflow-x: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
}

.catalogAside-specialities-list {
  grid-row: 2;
  overflow-y: auto;
}

@media (max-width: 1200px) {
  .catalogAside {
    grid-template-rows: auto auto 1fr;
    row-gap: 15px;
    padding: 40px 30px;
  }
}

@media (max-width: 550px) {
  .catalogAside {
    grid-template-rows: auto auto 1fr;
    row-gap: 15px;
    padding: 40px 20px;
  }
  .catalogAside {
    height: 80vh;
  }
}

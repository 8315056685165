.eventSponsors {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 64px;
  font-size: 12px;
}

.eventSponsors-organizers,
.eventSponsors-sponsors {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 64px;
}

.eventSponsor,
.eventOrganizer {
  color: var(--color-sponsor);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 128px;
}

.eventSponsor img,
.eventOrganizer img {
  max-height: 128px;
  max-width: 128px;
  height: auto;
  width: auto;
}

.eventSponsors-title {
  color: var(--color-sponsor-title);
  align-self: center;
}

@media (max-width: 1200px) {
  .eventSponsor img,
  .eventOrganizer img {
    max-height: 96px;
    max-width: 96px;
  }
}

@media (max-width: 550px) {
  .eventSponsors {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  .eventSponsors-organizers,
  .eventSponsors-sponsors {
    justify-content: center;
    column-gap: 24px;
  }
  .eventSponsor img,
  .eventOrganizer img {
    max-height: 64px;
    max-width: 64px;
  }
}

.eventHeader * {
  overflow-wrap: anywhere;
}

.eventInfo {
  display: grid;
  grid-template-columns: auto minmax(70%, 1fr);
  grid-template-areas:
    'date_format title'
    'options register'
    'options address'
    'specialities info'
    'linked linked';
  column-gap: 30px;
  row-gap: 24px;
}

.eventHeader-date_format {
  grid-area: date_format;
}

.eventHeader-date {
  display: flex;
  flex-wrap: wrap;
}

.eventHeader-linked {
  grid-area: linked;
}

.eventHeader-format {
  color: var(--color-format);
  background-color: var(--color-inverted);
  height: 33px;
  width: auto;
  min-width: 8em;
  max-width: 30vw;
  border-radius: 48px;
  align-self: start;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 0 8px;
}

.eventHeader-options {
  grid-area: options;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: start;
}

/* OPTIONS */

.eventHeader-option {
  display: grid;
  margin: 10px 0;
  grid-template-rows: 3fr 4fr;
  grid-template-columns: 46px auto;
  grid-template-areas:
    'icon title'
    'icon status';
  grid-column-gap: 12px;
}

.eventHeader-option-icon {
  grid-area: icon;
  height: 46px;
  width: 46px;
  margin: 0;
  border: 0.85px solid var(--color-inverted);
  border-radius: 23px;
  display: grid;
}
.eventHeader-option-icon img {
  display: block;
  margin: auto;
}
.eventHeader-option-title {
  grid-area: title;
  color: var(--color-label-inverted);
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
  align-self: flex-end;
}
.eventHeader-option-status {
  grid-area: status;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  align-self: start;
}

.eventHeader-linked {
  grid-area: linked;
}

/* TITLE */

.eventHeader-title {
  grid-area: title;
}

.eventHeader-title h2 {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

/* REGISTER */

.eventHeader-register {
  grid-area: register;
  display: grid;
  grid-template-columns: auto minmax(40%, 1fr);
  gap: 28px;
  align-items: center;
}

.eventHeader-register button {
  border: 1px solid var(--color-highlited-inverted);
  color: var(--color-highlited-inverted);
}

.eventHeader-register button.active {
  border-color: var(--color-active);
  color: var(--color-active);
}

.eventHeader-register .finished {
  font-size: 1.2em;
}

/* INFO  */
.eventHeader-address {
  grid-area: address;
}

.eventHeader-address .eventHeader-card-text {
  font-size: 20px;
}

.eventHeader-cards {
  grid-area: info;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'description'
    'control';
  gap: 24px;
}

.eventHeader-card-title {
  color: var(--color-label-inverted);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 6px;
}

.eventHeader-card-text {
  font-size: 14px;
  list-style-position: inside;
}

.eventHeader-card-download {
  margin-top: 0.5em;
}

.eventHeader-languageUnavaliable {
  font-size: 12px;
  font-style: italic;
  text-align: end;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.eventHeader-languageUnavaliable::before {
  content: '*';
  margin-right: 2px;
  color: var(--color-highlited-inverted);
}

@media (max-width: 1200px) {
  .eventHeader-title h2 {
    font-size: 26px;
  }

  .eventHeader-cards {
    grid-template-columns: 1fr;
    grid-template-areas:
      'description'
      'control';
    gap: 16px;
  }
}

@media (max-width: 550px) {
  .eventInfo {
    grid-template-columns: 1fr;
    grid-template-areas:
      'date_format'
      'title'
      'register'
      'options'
      'address'
      'specialities'
      'info'
      'linked';
  }

  .eventHeader-register {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  .eventHeader-address .eventHeader-card-text {
    font-size: 16px;
  }
  .eventHeader-card-text {
    font-size: 12px;
  }
  .eventHeader-languageUnavaliable {
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  .eventHeader-title h2 {
    font-size: 20px;
  }
  .eventHeader-address .eventHeader-card-text {
    font-size: 14px;
  }
}

.video {
  position: relative;
  width: 100%;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoframe {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  align-items: center;
}

.video:before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

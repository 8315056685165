.label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--color-label);
}

.inverted-bg .label {
  color: var(--color-label-inverted);
}

html {
  overflow-x: hidden;
}

.catalog {
  display: flex;
  flex-direction: row;
}

.catalog-aside {
  width: 420px;
  min-width: 15%;
  max-width: 40%;
}

.catalog-aside:last-child {
  flex: 1;
}

.catalog-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 25px 40px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.catalog-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4em;
}
.catalog-nav-aside {
  margin-top: 0.5em;
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  gap: 8px;
}

.catalog-list {
  flex: 1;
}

.catalog-archiveLink {
  text-transform: uppercase;
  margin: 32px 0;
  padding-top: 16px;
  border-top: 1px solid var(--color-label);
}

.catalog-footer {
  margin-top: 80px;
  font-size: 12px;
  color: var(--color-soft);
}

@media (max-width: 1200px) {
  .catalog-aside {
    width: 382px;
    max-width: 40%;
  }
  .catalog-nav {
    display: none;
  }
  .catalog-nav-aside {
    display: flex;
  }
  .catalog-main {
    padding: 30px;
  }
}

@media (max-width: 550px) {
  .catalog {
    flex-direction: column;
  }
  .catalog-main {
    padding: 20px;
  }
  .catalog-aside {
    width: 100%;
    max-width: 100%;
    max-width: initial;
    padding: 0px;
  }
}

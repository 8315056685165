.userEditProfile form .form-columns {
  grid-template-areas:
    'photo lastName city'
    'photo firstName work'
    'photo middleName position'
    'photo email speciality1'
    'photo phone speciality2'
    'photo none speciality3'
    'photo personalInfoAgreement personalInfoAgreement';
  grid-template-columns: 102px 1fr 1fr;
}

.userEditProfile .info {
  margin-bottom: 1em;
}

@media (max-width: 900px) {
  .userEditProfile form .form-columns {
    grid-template-areas:
      'photo lastName'
      'photo firstName'
      'photo middleName'
      'email email'
      'phone phone'
      'city city'
      'work work'
      'position position'
      'speciality1 speciality1'
      'speciality2 speciality2'
      'speciality3 speciality3'
      'personalInfoAgreement personalInfoAgreement';
    grid-template-columns: 102px 1fr;
  }
}

@media (max-width: 550px) {
  .userEditProfile form .form-columns {
    grid-template-areas:
      'photo'
      'lastName' 'firstName' 'middleName'
      'email' 'phone'
      'city' 'work' 'position'
      'speciality1' 'speciality2' 'speciality3'
      'personalInfoAgreement';
    grid-template-columns: 1fr;
  }
}

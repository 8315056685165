.selectLanguage-container {
  display: flex;
  align-items: start;
}

.selectLanguage {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.quiz {
  position: absolute;
  top: 0;
  bottom: 0;
  background: white;
  color: var(--color-main);
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 7px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  animation: quizAppearing 0.5s;
}
.quiz.submitted {
  height: 0;
  top: -100%;
  opacity: 0;
  transition: 0.5s;
}

@keyframes quizAppearing {
  from {
    transform: scaleY(0%);
    top: -100%;
    opacity: 0;
  }
  to {
    transform: scaleY(100%);
    top: 0;
    opacity: 100%;
  }
}

.quiz form {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.quiz-header {
  margin-bottom: 16px;
}

.quiz-header h4 {
  margin: 6px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.quiz .question {
  flex: 1;
  margin: 15px 0;
  overflow-y: auto;
}

.quiz .options {
  display: flex;
  flex-direction: column;
}

.question-text {
  margin-bottom: 16px;
}

.quiz .option {
  margin: 0;
  margin-bottom: 15px;
}

.quiz .option .MuiRadio-root,
.quiz .option .MuiCheckbox-root {
  align-self: flex-start;
}

.quiz label.option {
  color: var(--color-main);
}

button.quiz-submit {
  border: 1px solid var(--primary-main);
  color: var(--primary-main);
  width: 100%;
  font-weight: 500;
  text-transform: none;
}

button.quiz-submit:hover {
  color: var(--color-inverted);
  background-color: var(--primary-main);
}

.quiz-timer {
  position: relative;
  float: right;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  color: var(--primary-main);
}

.downloadFile {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  color: var(--color-link);
  text-decoration: underline;
}

.inverted-bg .downloadFile {
  color: var(--color-link-inverted);
}

.inverted-bg .popup .downloadFile {
  color: var(--color-link);
}

.downloadFile:hover {
  text-decoration: none;
}

.downloadFile.withIcon::before {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  display: inline-block;
  content: '';
  -webkit-mask: url('../_img/Download.svg');
  mask: url('../_img/Download.svg');
  -webkit-mask-size: cover;
  mask-size: cover;
  background: currentColor;
}

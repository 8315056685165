.catalogNav {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}

.catalogNav li {
  display: inline-block;
  margin-right: 40px;
}

.catalogNav li::before,
.catalogNav li::marker,
.catalogNav-aside li::before,
.catalogNav-aside li::marker {
  content: '';
}

.catalogNav a {
  color: inherit;
}

.catalogNav a:hover {
  color: var(--color-link);
}

@media (max-width: 1200px) {
  .catalogNav {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
  .catalogNav li {
    display: block;
    margin: 0;
  }
}

@media (max-width: 550px) {
  .catalogNav {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
  .catalogNav li {
    display: block;
    margin: 0;
  }
}

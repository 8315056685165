.authHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  position: relative;
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.authHeader-user {
  display: grid;
  grid-template-columns: auto 32px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.authHeader-name {
  text-align: end;
  margin-right: 8px;
}
.authHeader-avatar {
  color: var(--color-inverted);
  width: 30px;
  height: 30px;
}

.authHeader-user-menu {
  margin-top: 5px;
  width: max(auto, 100%);
  min-width: 12em;
  z-index: 100;
}

.authHeader-user-menu label {
  font-size: 0.9em;
}

.authHeader-login {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

@media (max-width: 1200px) {
  .authHeader {
    min-width: 0;
  }
  .authHeader-user {
    display: initial;
  }
  .authHeader-name {
    display: none;
    width: 0;
    margin-right: 0;
  }
  .authHeader-login {
    margin: 0;
    gap: 0;
  }
}

@media (min-width: 1200px) {
  .authHeader-user-menu label {
    display: none;
  }
}

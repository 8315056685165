.search {
  display: flex;
  justify-content: baseline;
}
.search-field-container {
  padding: 0;
  background-color: transparent;
  width: 12em;
  display: inline-block;
  transition: visibility 0s, width 0.3s ease, opacity 0.3s;
}
.search-field {
  transition: visibility 0s, width 0.3s ease;
}

.search-button {
  line-height: 1.5em;
  display: inline-flex;
  align-items: center;
}

.search-field-popper {
  padding: 12px;
}

.chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 70vh;
}

.chat .command-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.chat .users-count-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 4px;
  color: var(--color-placeholder-inverted);
  font-weight: bold;
}

.chat .users-count {
  font-size: 11px;
}

.chat .message-list-container {
  position: relative;
  flex: 1;
  padding: 0 8px;
  margin: 8px 0;
  display: flex;
  overflow: hidden;
}

.chat .message-list {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}

.chat .message-list-tobottom {
  position: absolute;
  bottom: 4px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: var(--bg-input);
  color: var(--color-active);
  opacity: 0.7;
  cursor: pointer;
  transition: 0.33s ease-out;
  z-index: 10;
}

.chat .message-list-tobottom[hidden] {
  opacity: 0;
  bottom: -46px;
}

.chat .message-list-tobottom:hover {
  opacity: 100%;
}

.chat .send-message {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'label label'
    'text send';
  align-items: center;
  padding: 6px 12px 12px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  max-height: 100%;
  overflow-y: auto;
  transition: 0.1s;
}

.chat .send-message.edit {
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'label label close'
    'delete text send';
}
.chat .send-message .send-message-delete {
  grid-area: delete;
  margin-left: -6px;
}
.chat .send-message .send-message-editLabel {
  grid-area: label;
}
.chat .send-message .send-message-close {
  grid-area: close;
  margin: -4px;
  text-align: end;
}
.chat .send-message .send-message-text {
  grid-area: text;
}
.chat .send-message .send-message-send {
  grid-area: send;
}

.chat .send-message button {
  align-self: end;
}

.chat div {
  padding: 0;
}

.chat .send-message input,
.chat .send-message textarea {
  color: var(--color-inverted);
  font-size: 14px;
}

.chat .send-message input::placeholder,
.chat .send-message textarea::placeholder {
  color: var(--color-placeholder-inverted);
}

.chat .send-message.disabled {
  background-color: rgba(0, 0, 0, 0.4);
}

.chat .chat-message {
  position: relative;
  font-size: 14px;
  margin-bottom: 8px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  animation: chatMessageAppear 0.5s;
  border-radius: 5px;
}
.chat .chat-message:hover {
  background-color: var(--bg-chatmessage);
}

.chat .chat-message .author {
  font-weight: 500;
  margin-right: 0.25em;
  display: inline;
}

.chat .chat-message .text {
  display: inline;
  color: var(--color-placeholder-inverted);
  align-self: center;
}

.chat .chat-message .date {
  position: absolute;
  color: var(--color-inverted);
  right: 0.5em;
  bottom: 0.5em;
  font-size: 0.7em;
}

.chat .chat-message.deleted {
  font-style: italic;
  font-size: small;
  opacity: 0.5;
}

.chat .chat-message.my {
  align-self: flex-end;
  animation: chatMessageAppearMy 0.5s;
}

.chat .chat-message.my:hover {
  background-color: var(--bg-chatmessage-my);
}

.chat .chat-message .edit-message {
  position: absolute;
  top: 25px;
  left: 28px;
  margin-right: -0.75em;
  margin-bottom: -0.75em;
  visibility: hidden;
}

.chat .chat-message .edit-message .MuiSvgIcon-root {
  font-size: 1.2em;
}
.chat .chat-message.my.active {
  background-color: var(--bg-chatmessage-my);
}
.chat .chat-message.active {
  background-color: var(--bg-chatmessage);
}
.chat .chat-message.editable {
  cursor: pointer;
}
.chat .chat-message.editable:hover .edit-message {
  visibility: visible;
}
.chat .chat-message.active .edit-message {
  visibility: visible;
}

.chat .chat-message .avatar {
  background-color: var(--bg-avatar);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  position: relative;
}

.chat .chat-message .text-container {
  flex: 1;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
  padding-bottom: 0.5em;
}

@keyframes chatMessageAppear {
  from {
    left: -100%;
    opacity: 0;
    transform: scaleX(0);
  }
  to {
    left: 0;
    opacity: 100%;
    transform: scaleX(100%);
  }
}

@keyframes chatMessageAppearMy {
  from {
    right: -100%;
    opacity: 0;
    transform: scaleX(0);
  }
  to {
    right: 0;
    opacity: 100%;
    transform: scaleX(100%);
  }
}

.Spinner-loading {
  width: 80%;
  margin: 2em auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Spinner-sending {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
}

.Spinner-submitting {
  display: inline-block;
  align-self: center;
  width: 1.5em;
  height: 1.5em;
}

.dateRangePicker {
  border: 1px solid var(--border-color);
  padding: 10px 16px 8px 16px;
  margin: 12px 0;
  border-radius: 3px;

  display: grid;
  grid-template-columns: 1fr 15% 1fr;
  column-gap: 5%;
  align-items: center;
}

.dateRangePicker .from-date {
  grid-column: 1;
}

.dateRangePicker .dash {
  grid-column: 2;
  border-bottom: 1px solid var(--border-color);
}

.dateRangePicker .to-date {
  grid-column: 3;
}

.dateRangePicker input {
  color: var(--color-inverted);
  padding: 2px 0px;
  text-align: center;
  cursor: pointer;
}

.dateRangePicker .MuiInput-underline::before {
  border-bottom: 0;
}

.dateRangePicker .MuiInput-underline:hover:not(.Mui-disabled)::before,
.dateRangePicker .MuiInput-underline:not(.Mui-disabled)::before {
  border-bottom-color: var(--border-color);
}

.popup-fullscreen {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: var(--bg-blur);
  backdrop-filter: blur(25px);
}

.popup-container {
  position: relative;
  display: grid;
}

.popup {
  margin: 0;
  padding: 20px;
  background: white;
  color: var(--color-main);
  border-radius: 7px;
  z-index: 10001;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: auto;
  animation: popupAppearing 0.5s;
}

.popup-fullscreen .popup {
  margin: auto;
  max-height: 90vh;
  width: 600px;
  max-width: 100vw;
}

.popup-container .popup {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@keyframes popupAppearing {
  from {
    transform: scaleY(0%);
    top: -100%;
    opacity: 0;
  }
  to {
    transform: scaleY(100%);
    top: 0;
    opacity: 100%;
  }
}

.popup form {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.inverted-bg .popup,
.inverted-bg .MuiPaper-root {
  color: var(--color-main);
}

.inverted-bg .popup .highlited,
.inverted-bg .popup a:hover,
.inverted-bg .MuiPaper-root .highlited,
.inverted-bg .MuiPaper-root a:hover {
  color: var(--color-highlited);
}

.popup .MuiRadio-root,
.popup .MuiCheckbox-root {
  margin-left: 12px;
  align-self: flex-start;
}

.popup-header {
  margin-bottom: 16px;
}

.popup-header h4 {
  margin: 6px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.popup-body {
  flex: 1;
}

.popup-footer {
  margin-top: 16px;
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.popup button {
  width: 100%;
  font-weight: 500;
  text-transform: none;
}

button.popup-submit {
  border-color: var(--primary-main);
  color: var(--color-inverted);
  background-color: var(--primary-main);
}

button.popup-submit:disabled {
  color: var(--color-inverted);
  background-color: var(--color-link-disabled);
}

button.popup-submit:not(:disabled):hover {
  color: var(--color-highlited-inverted);
  background-color: var(--primary-main);
}

button.popup-cancel {
  border-color: var(--primary-main);
  color: var(--primary-main);
}

button.popup-cancel:hover {
  background-color: var(--primary-main);
  color: var(--color-inverted);
}

.userPage-container {
  flex: 1;
  background-color: var(--bg-highlited);
  margin-top: calc(-74px - 48px);
  z-index: 1;
  padding: 30px 40px;
}

.userAccount {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 40px;
}

.userAccount-nav {
  display: flex;
  justify-content: flex-end;
  gap: 48px;
  font-size: 12px;
}

.userAccount-main {
  display: grid;
  grid-template-areas:
    'photo name contacts score'
    'photo specialities job score';
  grid-template-columns: auto 4fr 6fr minmax(200px, auto);
  gap: 22px;
}

.userAccount-photo {
  grid-area: photo;
  width: 76px;
  height: 76px;
  margin: 0 20px;
  background-color: var(--bg-photo);
}

.userAccount-personalInfo {
  grid-area: name;
  font-size: 20px;
  font-weight: 500;
}

.userAccount-specialities {
  grid-area: specialities;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.userAccount-contacts {
  grid-area: contacts;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.userAccount-job {
  grid-area: job;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userAccount-score {
  grid-area: score;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-score);
}

.userAccount-scorePoints {
  font-size: 102px;
  line-height: 74px;
  margin-bottom: 36px;
}

.userAccount-scorePoints-total {
  color: var(--color-score-total);
  font-weight: 300;
}

.UserAccount-info {
  display: grid;
  grid-template-columns: auto minmax(50%, 1fr);
  gap: 5px;
}

@media (max-width: 1200px) {
  .userPage-container {
    margin-top: calc(-24px - 48px);
    padding: 30px 20px;
  }
  .userAccount-nav {
    gap: 24px;
  }
  .userAccount-photo {
    margin: 0 5px;
  }
}

@media (max-width: 900px) {
  .userAccount-main {
    grid-template-areas:
      'photo name score'
      'specialities specialities score'
      'contacts contacts score'
      'job job score';
    grid-template-columns: auto 1fr minmax(180px, auto);
    gap: 22px;
  }
  .userAccount-scorePoints {
    font-size: 72px;
    line-height: 64px;
    line-height: initial;
    margin-bottom: 0;
  }
}

@media (max-width: 550px) {
  .userPage-container {
    margin-top: -48px;
    margin-left: -12px;
    margin-right: -12px;
  }
  .userAccount {
    gap: 20px;
  }
  .userAccount-nav {
    flex-wrap: wrap;
    font-size: 14px;
  }
  .userAccount-main {
    grid-template-areas:
      'photo name'
      'specialities specialities'
      'contacts contacts'
      'job job'
      'score score';
    grid-template-columns: auto 1fr;
    gap: 22px;
  }
  .userAccount-photo {
    width: 64px;
    height: 64px;
    margin: 0;
  }
}

@media (max-width: 350px) {
  .userPage-container {
    margin-top: -32px;
    margin-left: -16px;
    margin-right: -16px;
  }
}

.form-columns {
  display: grid;
  column-gap: 60px;
  row-gap: 20px;
  width: 100%;
}

form label {
  font-size: 12px;
  font-weight: 400;
}

form input {
  margin: 6px 0;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 400;
}

form input[disabled] {
  background-color: var(--bg-input-disabled);
  color: var(--color-disabled);
}

.required.field-label::after,
.required .field-label::after {
  content: ' *';
  color: var(--color-highlited);
}

.submitButton-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.field-error {
  font-size: 11px;
  color: var(--color-highlited);
}

.uploadFile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadFile-photo {
  grid-area: photo;
  width: 102px;
  height: 102px;
  margin: 10px auto;
  background-color: var(--bg-photo);
}
.uploadFile-button {
  border: 0;
  padding: 0 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.inputSelect {
  border: 1px solid var(--color-link);
  background-color: var(--bg-input);
  margin: 6px 0;
  padding: 12px 25px;
  box-sizing: border-box;
  border-radius: 3px;
  position: relative;
  transition: 0.1s linear 0.25s;
  cursor: pointer;
}

.inputSelect.open {
  background-color: var(--bg-focus-input);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  transition: 0s;
  cursor: default;
}

.inputSelect .selectedOption {
  position: relative;
}

.inputSelect .selectedOption:after {
  content: '▼';
  color: var(--color-link);
  position: absolute;
  right: 0.5em;
  top: 50%;
  font-size: 12px;
  margin: -7px;
  transition: 0.5s;
}

.inputSelect.open .selectedOption:after {
  rotate: 180deg;
}

.inputSelect .optionsList {
  position: absolute;
  display: grid;
  opacity: 0;
  height: 0;
  max-height: 80vh;
  width: calc(100% + 2px);
  margin: 0;
  left: -1px;
  transition: height 0.25s ease-out, opacity 0s linear 0.2s;
  z-index: 100;
  border: 1px solid var(--color-link);
  border-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: var(--bg-focus-input);
}

.inputSelect.open .optionsList {
  opacity: 100;
  padding: 25px;
  height: 400px;
  transition: height 0.5s ease-out, opacity 0s linear;
}
.inputSelect.open .optionsList-option {
  padding-bottom: 25px;
}

.inputSelect .optionsList ul {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  transition: height 0.5s ease-out, opacity 0s linear;
}

.inputSelect .optionsList .optionsList-option {
  display: block;
  cursor: pointer;
  background-color: var(--bg-focus-input);
}
.inputSelect .optionsList li::marker {
  content: '';
  display: none;
}
.inputSelect .optionsList .optionsList-option.selected {
  font-weight: bold;
  cursor: default;
}
.inputSelect .optionsList .optionsList-option.nothing {
  font-style: italic;
  padding-left: 0.1em;
}

@media (max-width: 550px) {
  .form-columns {
    column-gap: 20px;
    row-gap: 12px;
  }
}

.app {
  background-color: var(--bg-main);
  /*background-image: url('./_img/med_pattern_universitet_grey.png');
  background-repeat: repeat;
  background-size: 180px;*/
  min-height: 100vh;
  display: grid;
}

.inverted-bg {
  background: var(--bg-gradient);
  background-color: var(--bg-inverted);
  /*background-color: var(--color-samsmu-blue);
  background-image: url('./_img/med_pattern_universitet_lightblue.png');
  background-repeat: repeat;
  background-size: 180px;*/
  color: var(--color-inverted);
}

label,
.label {
  color: var(--color-label);
}

.inverted-bg label,
.inverted-bg .label {
  color: var(--color-label-inverted);
}

.highlited {
  color: var(--color-highlited);
}
.inverted-bg .highlited {
  color: var(--color-highlited-inverted);
}

.pointer {
  cursor: pointer;
}

.link,
a {
  color: var(--color-main);
  cursor: pointer;
}
button {
  color: var(--color-link);
  background-color: transparent;
  cursor: pointer;
  border: none;
}
button:disabled {
  cursor: auto;
  color: var(--color-link-disabled);
}

button.button {
  border: 1px solid var(--color-link);
  border-radius: 3px;
  min-height: 48px;
  font-size: 18px;
  padding: 12px 25px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

button.button:disabled {
  border-color: var(--color-link-disabled);
  color: var(--color-link-disabled);
}

.inverted-bg button,
.inverted-bg a {
  color: var(--color-inverted);
}
.inverted-bg button {
  border-color: var(--color-link-inverted);
}

.link:hover,
a:hover {
  color: var(--color-link);
}

.inverted-bg button:hover,
.inverted-bg a:hover {
  color: var(--color-link-inverted);
}

.logoFull {
  width: 200px;
}

.hidden,
*[hidden] {
  display: none !important;
}

input {
  display: block;
  color: var(--color-main);
  border: 1px solid var(--color-link);
  background-color: var(--bg-highlited);
  border-radius: 3px;
  width: 100%;
}

input[type='checkbox'] {
  display: inline;
  width: auto;
  margin: 0 0.5em;
}

body * {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) var(--border-color-opaque);
}
.catalog-main {
  /* Firefox */
  scrollbar-width: initial;
  scrollbar-color: initial;
}

body *:not(.catalog-main)::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
body *:not(.catalog-main)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 1px var(--border-color-opaque);
  border: solid 2px transparent;
}
body *:not(.catalog-main)::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: var(--border-color);
  backdrop-filter: blur(4px);
  border-radius: 27px;
}

.avatar {
  border-radius: 50%;
  background-color: var(--bg-avatar);
  fill: var(--color-inverted);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.avatar img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

.avatar img.empty {
  height: 51px;
  width: 51px;
}

.dropdown {
  margin-top: 6px;
}

@media (max-width: 1200px) {
  .logoFull {
    width: 155px;
    max-width: 100%;
  }
}

@media (max-width: 550px) {
  h1 {
    font-size: 26px;
  }
}

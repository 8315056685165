:root {
  --bg-main: #f9f9f9;
  --bg-highlited: #ffffff;
  --bg-inverted: #4668cd;
  --bg-gradient: linear-gradient(
    173.23deg,
    #0077c9 -8.21%,
    #4668cd 95.15%,
    #006cb7 110.1%
  );
  --bg-marquee: #06a9b3;
  --bg-avatar: #46b3ff;
  --bg-photo: #266fcb;
  --bg-input: #ffffff;
  --bg-focus-input: #f4f8fd;
  --bg-input-disabled: #eee;
  --bg-blur: #144fa788;
  --bg-chatmessage: #144fa730;
  --bg-chatmessage-my: #0c916f30;
  --color-main: #000;
  --color-inverted: #fff;
  --color-highlited: #ff0000;
  --color-highlited-inverted: #fcff62;
  --color-active: #2bcdcd;
  --color-soft: #848484;
  --color-regalia: #7b7b7b;
  --color-label: #b5b5b5;
  --color-label-inverted: #65a9da;
  --color-now: #3b9b5c;
  --color-link: #076cb3;
  --color-link-inverted: #fcff62;
  --color-link-disabled: #b5b5b5;
  --color-placeholder-inverted: #d2e3ff;
  --color-sponsor: #9e9e9e;
  --color-sponsor-title: #878787;
  --color-format: #2070ca;
  --color-score: #3c7ed0;
  --color-score-total: #cacaca;
  --color-disabled: #888;
  --color-danger: #ee3333;
  --color-success: #00c300;
  --border-color: #258fdb;
  --border-color-opaque: #258fdb70;
  --border-color-chat: #5e91ff;
  --timeline-color: #c8c8c8;
  /* MUI в appTheme.tsx */
  --primary-main: #33c3c3;
  --primary-light: #c8c8c8;
  --primary-dark: #000;
  --secondary-main: #fff;
  --secondary-light: #d2e3ff;
  --secondary-dark: #848484;
  /* SAMSMU firm colors */
  --color-samsmu-blue: rgb(0, 108, 180);
  --color-samsmu-lightblue: rgb(48, 145, 209);
  --color-samsmu-grey: rgb(237, 237, 237);
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

body {
  background: var(--bg-main);
  color: var(--color-main);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}

a.link {
  text-decoration: underline;
  color: var(--color-link);
}

.text-danger {
  color: var(--color-danger) !important;
}

.text-success {
  color: var(--color-success) !important;
}

p {
  margin-bottom: 1em;
}

.webinar {
  position: relative;
  display: grid;
  grid-template-columns: 1fr minmax(300px, 25%);
  column-gap: max(40px, 1%);
  min-height: 320px;
}

.webinar.novideo {
  grid-template-columns: 1fr;
}

.webinar-video {
  position: relative;
  display: grid;
}

.webinar-chat {
  display: grid;
  position: relative;
  height: 100%;
  min-height: 25em;
}

.popup-container {
  position: relative;
  display: grid;
}

@media (max-width: 1200px) {
  .webinar {
    column-gap: max(20px, 1%);
  }
}

@media (max-width: 800px) {
  .webinar {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(210px, auto) 1fr;
  }
}

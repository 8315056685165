body {
  overflow-x: hidden;
}
.eventsList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 30px;
}

.eventsList h3 {
  margin: 24px 0;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

.catalog .eventsList h3 {
  text-transform: capitalize;
}

.eventsList table {
  border-collapse: collapse;
}

.catalog .eventsList table {
  width: calc(100% + 160px);
  table-layout: fixed;
  margin: 0 -80px;
}

.catalog .eventsList table tr {
  border-left: 80px solid;
  border-right: 80px solid;
  border-color: transparent;
}

.eventsList thead {
  font-weight: normal;
  font-size: small;
  color: var(--color-label);
}

.eventsList thead th {
  font-weight: normal;
  text-align: left;
  border-bottom: 1px solid var(--color-label);
  padding-bottom: 8px;
}

.eventsList tbody tr:hover {
  background-color: var(--bg-highlited);
  border-color: var(--bg-highlited);
  position: relative;
}

.eventsList tbody td {
  padding: 24px 0;
  vertical-align: top;
}

.eventsList tbody tr td:first-child {
  position: relative;
}

.catalog .eventsList tbody tr:hover td:first-child::before {
  content: url('../_img/Finift.svg');
  height: 25px;
  position: absolute;
  left: -44px;
  top: 1.1em;
  margin: 0;
  color: var(--color-link);
}

.eventsList tbody tr td:last-child {
  padding-right: 36px;
  position: relative;
}
.eventsList tbody tr:hover td:not(.link):last-child::after,
.eventsList tbody tr td.link:last-child:hover::after {
  content: '🠒';
  font-size: 36px;
  font-stretch: expanded;
  position: absolute;
  top: 50%;
  right: 0px;
  margin: -0.5em 0;
  line-height: 1em;
  color: var(--color-link);
}

.eventsList .col-date {
  width: 185px;
  min-width: auto;
}

.eventsList .col-time {
  width: 8%;
  min-width: auto;
}

.eventsList .col-code {
  width: 12%;
  min-width: auto;
}

.eventsList .col-type {
  width: 15%;
  min-width: auto;
}

.eventsList .col-score {
  width: 10%;
}

.eventsList .eventDate-date {
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
}

.eventsList .eventDate-day {
  font-size: small;
  color: var(--color-soft);
}

.eventsList .eventDate-day .weekend {
  color: var(--color-highlited);
}

.eventsList td.event-points {
  color: var(--color-link);
  font-weight: 500;
}

@media (max-width: 1200px) {
  .eventsList h3 {
    margin-top: 24px;
    font-size: 24px;
  }

  .eventsList thead {
    display: none;
  }

  .catalog .eventsList tbody tr:hover td:first-child::before {
    display: none;
  }
  .eventsList tbody tr:hover td:last-child::after {
    display: none;
  }
  .eventsList tbody tr td:last-child {
    padding-right: 0;
  }
  .eventsList table {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .eventsList tbody tr {
    padding: 32px 0;
    display: grid;
    grid-template-columns: 7.5em auto 1fr;
    grid-template-areas:
      'date time type'
      'date time title'
      'points points code';
    gap: 20px;
  }
  .eventsList tbody td {
    padding: 0;
  }

  .eventsList td.event-date {
    grid-area: date;
  }

  .eventsList td.event-time {
    grid-area: time;
  }

  .eventsList td.event-type {
    grid-area: type;
  }

  .eventsList td.event-code {
    grid-area: code;
  }

  .eventsList td.event-points {
    grid-area: points;
  }

  .eventsList td.event-title {
    grid-area: title;
  }
}

@media (max-width: 800px) {
  .eventsList tbody tr {
    grid-template-columns: 4.25em auto 1fr;
    gap: 24px;
  }
}

@media (max-width: 550px) {
  .eventsList table {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .eventsList tbody tr {
    padding: 32px 0px;
    display: grid;
    grid-template-columns: 4.25em 1fr auto;
    grid-template-areas:
      'date time type'
      'date title title'
      'points code code';
    gap: 24px;
  }
  .eventsList tbody td {
    padding: 0;
  }
  .eventsList td.event-title {
    overflow-wrap: anywhere;
  }
  .eventsList td.event-title {
    overflow-wrap: anywhere;
  }
}

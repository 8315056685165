.message {
  border-radius: 3px;
  padding: 6px 16px;
  margin: 0.5em;
}

.message.error {
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);
}

.message.success {
  color: rgb(35, 75, 17);
  background-color: rgb(220, 236, 220);
}
